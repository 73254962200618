import React from "react"
import { Link } from "gatsby"

function Card(props) {
  
  return (
    <div className={"border text-center border-gray-100 h-full flex shadow-lg w-full bg-" + props.farge+"-50"}>
      <img className="w-32 object-cover" src={props.bilde} alt={props.overskrift} />
      <div className="flex flex-col p-2 justify-between w-full">
        <div>
          <h2 className={"text-lg uppercase font-semibold text-" + props.farge + "-900"}>
            {props.overskrift}
          </h2>
          <p className="text-sm pb-2">{props.tekst}</p>
        </div>

        <div className="w-full mt-4">
          <Link
            className={"text-green-50 px-3 py-1 rounded-md bg-" + props.farge + "-700"}
            to={props.lenke}
          >
            <span className="text-sm">{props.lenkeTekst}</span>
          </Link>
        </div>
        <div className="invisible bg-green-50 bg-pink-50 bg-red-50 bg-indigo-50 bg-yellow-50 bg-gray-50 bg-blue-50 bg-pink-50 bg-purple-50
            text-red-900 text-pink-900 text-green-900 text-blue-900 text-gray-900 text-green-900 text-yellow-900 text-purple-900
            bg-green-700 bg-pink-700 bg-yellow-700 bg-blue-700 bg-red-700 bg-indigo-700 bg-gray-700 bg-purple-700">
          Hidden
        </div>
      </div>
    </div>
  )
}

export default Card
