import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Card from "../components/card"
import IsvassnipaImg from "../img/isvassnipa.jpg"
import KveldsturImg from "../img/kveldstur.jpg"
import CubeMtbImg from "../img/cube_mtb.png"
import Norco from "../img/norco.jpg"
import KanoImg from "../img/kano_haheimsvatnet.jpg"

import TrekImg from "../img/utleige_trek.jpg"
import Hopping from "../img/hopping_pa_vatnet.jpg"
import Topptur from "../img/topptur.jpg"
import Fellestur from "../img/fellestur.png"



export default function Home() {
  return (
    <Layout>
      <img
        className="h-40 md:h-56 lg:h-64 xl:h-72 2xl:h-96 w-full object-cover object-center rounded-lg shadow-lg mt-6"
        src={IsvassnipaImg}
        alt="På veg opp til Isvassnipa i solnedgang"
      />

      

      <div className="">
        <div className="py-12 text-center">
          <h3 className="text-green-800 font-semibold">
            Få tilsendt informasjon om aktivitetane våre på epost
          </h3>
          <div className="mt-6">
            <Link
              to="/nyheitsbrev/"
              className="hover:bg-yellow-300 text-yellow-50 px-4 py-2 rounded-md mt-4 shadow-md bg-yellow-400"
            >
              Meld deg på nyheitsbrevet
            </Link>
          </div>
        </div>

        <div className="mb-12 gap-x-4 gap-y-6 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
          <Card
            lenke="/aktivitetar/"
            lenkeTekst="På melding til aktivitet"
            overskrift="Påmelding"
            bilde={Fellestur}
            tekst="Her kan du melde deg på Jølster Adventure sine aktivitetar"
            farge="blue"
          />
          <Card
            lenke="/topptur/"
            lenkeTekst="Meir info og bestilling"
            overskrift="Guidet topptur"
            bilde={Topptur}
            tekst="Ønskjer du å gå på topptur i Jølster. Vi viser veg."
            farge="blue"
          />
           <Card
            lenke="/topptur/"
            lenkeTekst="Meir info og bestilling"
            overskrift="Kveldstur med hodelykt"
            bilde={KveldsturImg}
            tekst="Opplev Jølsternaturen ved å bli med på kveldstur med hodelykt!"
            farge="purple"
          />          
          <Card
            lenke="/stisykling/"
            lenkeTekst="Meir info om stisykling"
            overskrift="Stisykling"
            bilde={CubeMtbImg}
            tekst="Bli med oss på stisykling i Jølster!"
            farge="yellow"
          />         
          <Card
            lenke="/vannsport/"
            lenkeTekst="Meir info"
            overskrift="Vannsport"
            bilde={Hopping}
            tekst="Info om hoppanlegget og hopping på vatnet i Kjøsnesfjorden!"
            farge="indigo"
          />          
          <Card
            lenke="/guiding/"
            lenkeTekst="Meir info og bestilling"
            overskrift="Stisykling med guide"
            bilde={Norco}
            tekst="Vi viser deg dei beste stiane på og i området rundt Jølster!"
            farge="green"
          />
          <Card
            lenke="/vannsport/"
            lenkeTekst="Info og bestilling"
            overskrift="Kanopadling på Håheimsvatnet"
            bilde={KanoImg}
            tekst="Ta deg ein kanopadletur på Håheimsvatnet."
            farge="gray"
          />
          <Card
            lenke="/stisykling/"
            lenkeTekst="Leige av stisykkel"
            overskrift="Utleige av stisykel"
            bilde={TrekImg}
            tekst="Vi har fleire sti og terrengsyklar som det er mogleg å leige!"
            farge="yellow"
          />
        </div>
      </div>
    </Layout>
  )
}
